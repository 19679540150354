module.exports = {
  fr: {
    errors: {
      matchPasswords: 'Les mots de passe ne correspondent pas',
      required: 'Obligatoire',
      errorSavingData: 'Erreur pendant l\'enregistrement',
      errorSavingDuplicate: 'Un enregistrement similaire existe déjà',
      updatePassword: 'Erreur pendant l\'enregistrement du mot de passe',
      updatePasswordInfo: 'Vérifiez que vous avez bien saisi votre mot de passe actuel',
      unauthorizedAccount: 'Ce compte n\'a pas accès à l\'application',
      noSubscription: 'La souscription de ce compte n\'est pas valide',
      invalidAuthorization: 'Ce compte n\'a pas l\'autorisation d\'accéder à l\'application',
      incompatibleVersion: 'La version de votre application n\'est pas compatible avec le serveur que vous utilisez',
      noLines: 'Vous ne disposez pas d\'une ligne téléphonique. Veuillez contacter votre administrateur',
      callRoutingError: 'Erreur durant le routage de votre appel',
      whitelabelFetchError: 'Erreur durant le chargement de l\'application. Merci de recharger la page ou de réessayer plus tard.',
      noMatch: {
        title: 'Erreur 404',
        description: 'Cette page n\'existe pas',
      },
    },
    general: {
      placeholder: 'REMPLISSEUR d\'ESPACE',
      mission: 'Mission',
      profil: 'Profil',
      logout: 'Déconnexion',
      cancel: 'Annuler',
      or: 'ou',
      calendar: {
        date: 'DD/MM/YYYY',
        month: 'YYYY MMMM',
        week: 'dd',
      },
      date: {
        formats: {
          calledAt: 'HH\'h\'mm',
          callDurationLong: 'hh:mm:ss',
          callDurationShort: 'mm:ss',
          fullDate: 'yyyy-MM-dd',
        },
      },
      goBack: 'Retour',
      makeCall: 'Passer un appel',
      callerId: 'Identifiant d\'appelant',
      default: 'Défaut',
      setAsDefault: 'Définir par défaut',
      types: {
        main: 'Numéro de l\'organisation',
        associated: 'Numéro direct',
      },
      searchInWazo: 'Rechercher',
      searchNumberOrContact: 'Entrer un nom ou numéro',
      today: 'aujourd\'hui',
      yesterday: 'hier',
      settings: 'Paramètres',
      disconnect: 'Déconnexion',
      available: 'Disponible',
      doNotDisturb: 'Ne pas déranger',
      notAvailable: 'Indisponible',
      invisible: 'Invisible',
      disconnected: 'Déconnecté',
      notifications: 'Notifications',
      visualNotifications: 'Notifications visuelles',
      clearNotifications: 'Effacer les notifications',
      availability: 'Disponibilité',
      status: 'Statut',
      unknownParty: 'Contact inconnu',
      onTheMove: 'En déplacement',
      vacation: 'Vacances',
      remote: 'Télétravail',
      inMeeting: 'En réunion',
      addStatus: 'Définir un statut...',
      extension: 'Extension',
      callHelp: 'Entrer un numéro de téléphone ou le nom d\'une personne',
      callNumber: 'Appeller "{{number}}"',
      loading: 'Chargement en cours',
      redirectionMessage: 'Vous devriez être redirigé vers Wazo Desktop',
      backToWebApp: 'Retour vers Wazo Web',
    },
    settings: {
      audioVideo: 'Audio/Vidéo',
      connection: 'Connexion',
      phone: 'Téléphone',
      callForwarding: 'Renvoi d\'appel',
      unconditional: 'Inconditionnel',
      noAnswer: 'Pas de réponse',
      busy: 'Occupé',
      user: 'Utilisateur',
      history: 'Historique',
      lineSelection: 'Choix de la ligne',
      line: 'Ligne',
      webRtc: 'Softphone',
      password: 'Mot de passe',
      userInfo: 'Information utilisateur',
      changePassword: 'Modifier mot de passe',
      updateInformation: 'Modifier les informations',
      save: 'Enregistrer',
      cancel: 'Annuler',
      currentPassword: 'Mot de passe actuel',
      newPassword: 'Nouveau mot de passe',
      confirmPassword: 'Confirmer mot de passe',
      passwordChangeSuccess: 'Mot de passe modifié avec succès',
    },
    languages: {
      french: 'Français',
      english: 'Anglais',
      language: 'Langue',
      save: 'Sauvegarder',
    },
    networkState: {
      title: 'État de votre connexion',
      unstableConnection: 'Votre connexion semble instable',
      badConnection: 'Votre connexion est très instable, Wazo ne peut fonctionner correctement.',
      disconnected: 'Vous ne semblez pas être connecté à Internet',
    },
  },
  en: {
    errors: {
      matchPasswords: 'Passwords do not match',
      required: 'Required',
      errorSavingData: 'Error while saving',
      errorSavingDuplicate: 'A similar record already exists',
      updatePassword: 'Error while updating your password',
      updatePasswordInfo: 'Please verify that you have correctly entered your current password',
      unauthorizedAccount: 'This account doesn\'t have access to the application',
      noSubscription: 'This account\'s subscription is not valid',
      invalidAuthorization: 'This account doesn\'t have the authorisation to access this application',
      incompatibleVersion: 'The version of your application is not compatible with the server you use.',
      noLines: 'You don\'t have any lines. Please contact your administrator.',
      callRoutingError: 'There was an error routing your call',
      whitelabelFetchError: 'There was an error during the application loading. Please reload the page or try again later.',
      noMatch: {
        title: 'Error 404',
        description: 'This page does not exist',
      },
    },
    general: {
      placeholder: 'PLACEHOLDER',
      mission: 'Mission',
      profil: 'Profile',
      logout: 'Log out',
      cancel: 'Cancel',
      or: 'or',
      calendar: {
        date: 'MM/DD/YYYY',
        month: 'MMMM YYYY',
        week: 'dd',
      },
      date: {
        formats: {
          calledAt: 'HH:mm',
          callDurationLong: 'hh:mm:ss',
          callDurationShort: 'mm:ss',
          fullDate: 'yyyy-MM-dd',
        },
      },
      goBack: 'Back',
      makeCall: 'Make a call',
      callerId: 'Caller ID',
      default: 'Default',
      setAsDefault: 'Set as default',
      types: {
        main: 'Organization number',
        associated: 'Direct number',
      },
      searchInWazo: 'Search',
      searchNumberOrContact: 'Search name or number',
      today: 'today',
      yesterday: 'yesterday',
      settings: 'Settings',
      disconnect: 'Logout',
      available: 'Available',
      doNotDisturb: 'Do not disturb',
      notAvailable: 'Not available',
      invisible: 'Invisible',
      notifications: 'Notifications',
      visualNotifications: 'Visual notifications',
      clearNotifications: 'Clear notifications',
      disconnected: 'Disconnected',
      availability: 'Availability',
      status: 'Status',
      unknownParty: 'Unknown party',
      onTheMove: 'On the move',
      vacation: 'Vacation',
      remote: 'Remote',
      inMeeting: 'In meeting',
      addStatus: 'Set a status...',
      extension: 'Extension',
      callHelp: 'Just type in a phone number or a person\'s name',
      callNumber: 'Call "{{number}}"',
      loading: 'Loading in progress',
      redirectionMessage: 'This should redirect you to Wazo Desktop',
      backToWebApp: 'Back to Wazo Web',
    },
    settings: {
      audioVideo: 'Audio/Video',
      connection: 'Connection',
      phone: 'Phone',
      callForwarding: 'Call Forwarding',
      unconditional: 'Unconditional',
      noAnswer: 'No answer',
      busy: 'Busy',
      user: 'User',
      history: 'History',
      lineSelection: 'Line selection',
      line: 'Line',
      webRtc: 'Softphone',
      password: 'Password',
      userInfo: 'User information',
      changePassword: 'Change password',
      updateInformation: 'Update the information',
      save: 'Save',
      cancel: 'Cancel',
      currentPassword: 'Current password',
      newPassword: 'New password',
      confirmPassword: 'Confirm password',
      passwordChangeSuccess: 'Password sucessfully changed',
    },
    languages: {
      french: 'French',
      english: 'English',
      language: 'Language',
      save: 'Save',
    },
    networkState: {
      title: 'Connection state',
      unstableConnection: 'Your connection seems unstable',
      badConnection: 'Your connection is very unstable, Wazo can\'t work correctly.',
      disconnected: 'You don\'t seem connected to Internet',
    },
  },
};
