module.exports = {
  fr: {
    meetings: {
      meetingWith: 'Réunion avec {{name}}',
      creatingRoom: 'Création de «{{room}}»...',
      copyLink: 'Copier le lien',
      sendLink: 'Envoyer le lien',
      roomLink: 'Lien vers la chambre',
      linkCopiedToClipboard: 'Lien copié dans le presse-papier',
      linkCopied: 'Lien copié',
      calendar: 'Calendrier',
      addParticipants: 'Ajouter des participants',
      createMeeting: 'Créer une réunion',
      shareMeeting: 'Partager un évènement',
      roomTitle: 'Titre de la chambre',
      eventDate: 'Date',
      eventStart: 'Début',
      eventEnd: 'Fin',
      eventDescription: 'Description (facultatif)',
      endIsBeforeStart: 'La date de fin doit être après la date de début',
      create: 'Créer',
      created: 'Créé',
      cancel: 'Annuler',
      sendToCalendar: 'Envoyer au calendrier',
      sendMailBody: '{{name}} vous a envoyé un lien vers un meeting Wazo',
      meetings: 'Réunions',
      noMeetings: 'Aucune réunion active',
      confirmMeetingDeletion: 'Êtes-vous sûr de vouloir supprimer «{{meeting}}»?',
      deletingMeeting: 'Suppression de la réunion...',
      persistentRoom: 'Persistante',
      createMeetingEvent: 'Partager un évènement pour {{meetingName}}',
      createTemporaryMeeting: 'Créer une réunion temporaire',
      createLaterMeeting: 'Créer une réunion',
      delete: 'Supprimer',
      moderatedNotice: 'Wazo Desktop/Web doit être utilisé pour permettre aux participants de rejoindre cette réunion',
      moderatedMeetingMessage: 'L\'utilisation de Wazo Desktop/Web est nécessaire pour permettre aux participants de rejoindre des réunions modérées',
      shareModeratedNotice: 'Modération activée',
      shareModeratedNoticeMessage: 'Wazo Desktop/Web doit être utilisé pour permettre aux participants de rejoindre',
      prefilledEventDescription: 'Êtes-vous un organisateur de cette réunion ? Utilisez Wazo',
      desktop: 'Desktop',
      web: 'Web',
      meetingAccessTimeout: 'Problème d\'accès à la réunion',
      tempMeetingMessage: 'Note: les réunions temporaires sont automatiquement supprimées en fin de journée.',
      temporaryMeeting: 'Réunion temporaire',
      meetingCreationError: 'Une erreur est survenue lors de la création de la réunion',
      meetingAccessError: 'Une erreur est survenue en tentant d\'accéder la réunion',
      meetingsBlurb: 'Organisez des réunions virtuelles jusqu\'à {{maxParticipants}} participants',
      selectCalendar: 'Sélectionnez un calendrier',
      google: 'Google',
      outlook: 'Outlook',
      office365: 'Office 365',
      yahoo: 'Yahoo',
      ics: 'ICS',
      downloadIcs: 'Télécharger l\'ICS',
      access: {
        disableModeration: 'Désactiver la modération',
        enableModeration: 'Activer la modération',
        disabledModeration: 'La modération est désactivée',
        enabledModeration: 'La modération est activée',
        request: ' demande accès à la réunion',
        requestTitle: 'Requêtes d\'accès',
        deny: 'Refuser',
        grant: 'Accepter',
        grantSelected: 'Accepter',
        denySelected: 'Refuser',
        selectAll: 'Tout cocher',
        deselectAll: 'Tout décocher',
        confirmBulkGrant: 'Êtes-vous certain de vouloir accepter les participants sélectionnés',
        confirmBulkDeny: 'Êtes-vous certain de vouloir refuser les participants sélectionnés',
        processingBulkGrant: 'Acceptation en cours...',
        processingBulkDeny: 'Refus en cours...',
        actions: 'Actions',
        name: 'Nom',
        requireAuthorization: 'Modération',
        states: {
          label: 'Contrôle de l\'accès',
          short: {
            locked: 'Vérrouillé',
            controlled: 'Contrôlé',
            open: 'Ouvert à tous',
          },
          long: {
            locked: 'Je veux vérrouiller l\'accès pour le moment',
            controlled: 'Je veux garder le contrôle sur l\'accès',
            open: 'Tous les usagers sont acceptés automatiquement',
          },
        },
      },
    },
  },
  en: {
    meetings: {
      meetingWith: 'Meeting with {{name}}',
      creatingRoom: 'Creating "{{room}}"...',
      copyLink: 'Copy link',
      sendLink: 'Send link',
      roomLink: 'Link to the room',
      linkCopiedToClipboard: 'Link copied to the clipboard',
      linkCopied: 'Link copied',
      calendar: 'Calendar',
      addParticipants: 'Add participants',
      createMeeting: 'Create meeting',
      shareMeeting: 'Share event',
      roomTitle: 'Room Title',
      eventDate: 'Date',
      eventStart: 'Start',
      eventEnd: 'End',
      eventDescription: 'Description (optional)',
      endIsBeforeStart: 'End date must be after start date',
      create: 'Create',
      created: 'Created',
      cancel: 'Cancel',
      sendToCalendar: 'Send to Calendar',
      sendMailBody: '{{name}} has sent you a link to a Wazo meeting',
      meetings: 'Meetings',
      noMeetings: 'No active meetings',
      confirmMeetingDeletion: 'Are you sure you want to delete "{{meeting}}"?',
      deletingMeeting: 'Deleting meeting...',
      persistentRoom: 'Persistent',
      createMeetingEvent: 'Share an event for {{meetingName}}',
      createTemporaryMeeting: 'Create a temporary meeting',
      createLaterMeeting: 'Create a meeting',
      delete: 'Delete',
      moderatedNotice: 'Wazo Desktop/Web must be used to let participants join this meeting',
      moderatedMeetingMessage: 'Using Wazo Desktop/Web is required to let participants join moderated meetings',
      shareModeratedNotice: 'Moderation enabled',
      shareModeratedNoticeMessage: 'Wazo Desktop/Web must be used to let participants join',
      prefilledEventDescription: 'Are you hosting this meeting? Use Wazo',
      desktop: 'Desktop',
      web: 'Web',
      meetingAccessTimeout: 'There was a problem accessing the meeting',
      tempMeetingMessage: 'Note: temporary meetings are automatically deleted at the end of the day',
      temporaryMeeting: 'Temporary Meeting',
      meetingCreationError: 'Error creating meeting',
      meetingAccessError: 'Error accessing meeting',
      meetingsBlurb: 'Set up virtual meetings up to {{maxParticipants}} attendees',
      selectCalendar: 'Select a calendar',
      google: 'Google',
      outlook: 'Outlook',
      office365: 'Office 365',
      yahoo: 'Yahoo',
      ics: 'ICS',
      downloadIcs: 'Download ICS',
      access: {
        disableModeration: 'Disable moderation',
        enableModeration: 'Enable moderation',
        disabledModeration: 'Moderation is disabled',
        enabledModeration: 'Moderation is enabled',
        request: 'is requesting access to the meeting',
        requestTitle: 'Access requests',
        deny: 'Deny',
        grant: 'Grant',
        grantSelected: 'Grant',
        denySelected: 'Deny',
        selectAll: 'Select all',
        deselectAll: 'Deselect all',
        confirmBulkGrant: 'Are you sure you want to grant access to selected users?',
        confirmBulkDeny: 'Are you sure you want to deny access to selected users?',
        processingBulkGrant: 'Granting access to all users...',
        processingBulkDeny: 'Denying access to all users...',
        actions: 'Actions',
        name: 'Name',
        requireAuthorization: 'Moderation',
        states: {
          label: 'Access control',
          short: {
            locked: 'Locked',
            controlled: 'Controlled',
            open: 'Open to all',
          },
          long: {
            locked: 'I want to prevent anyone from entering for now',
            controlled: 'I want to control who can access',
            open: 'All users are automatically granted access',
          },
        },
      },
    },
  },
};
