module.exports = {
  fr: {
    switchboard: {
      incomingCalls: 'Entrants ({{quantity}})',
      incomingSwitchboardCall: 'Appel POPC entrant',
      multipleIncomingCalls: 'Appels POPC mulitples',
      heldCalls: 'File d\'attente partagée ({{quantity}})',
      inProgress: 'En cours',
      noOngoingCall: 'Aucun appel en cours',
      noIncomingCall: 'Aucun appel entrant',
      noHeldCall: 'Aucun appel en attente',
    },
  },
  en: {
    switchboard: {
      incomingCalls: 'Incoming  ({{quantity}})',
      incomingSwitchboardCall: 'Incoming switchboard call',
      multipleIncomingCalls: 'Multiple switchboard calls',
      heldCalls: 'Shared Waiting Queue ({{quantity}})',
      inProgress: 'In progress',
      noOngoingCall: 'No ongoing call',
      noIncomingCall: 'No incoming call',
      noHeldCall: 'No held call',
    },
  },
};
