/* eslint-disable quotes */

module.exports = {
  fr: {
    banners: {
      doNotDisturbActive: '«Ne pas déranger» activé',
      doNotDisturbActiveByline: 'Les appels seront envoyés vers la messagerie vocale.',
      disableDoNotDisturb: 'Désactiver',
      redirectionsActive: "Renvoi d'appel activé.",
      initialPermissionRequestP1: 'Wazo a besoin de votre permission pour ',
      initialPermissionRequestP2: 'activer les notifications',
      userDeniedPermissions:
        "Pour une meilleure utilisation de Wazo, nous vous recommandons d'activer les notifications.",
      doNotAskAgainOnComputer: 'Ne plus demander sur cet ordinateur',
      later: 'Plus tard',
      activatePermissions: 'Activer les permissions',
      webRtcDownStatus:
        "Le serveur de téléphonie semble injoignable. Wazo pourrait ne pas recevoir ou passer d'appels",
      websocketDownStatus: 'Wazo semble incapable de recevoir des messages, les informations ne pourront pas être mises à jour automatiquement.',
      websocketDownRefresh: 'Reconnecter',

      desktopUpdateAvailable:
        'Une nouvelle version {{ version }} est disponible.',
      desktopUpdateAvailableAction: 'Télécharger',
      desktopUpdateDownloaded:
        'La nouvelle version {{ version }} est maintenant prête à être installée',
      desktopUpdateDownloadedAction: "Installer et relancer l'application",
      desktopUpdateDownloading:
        'Téléchargement de la nouvelle version: {{percent}}%',
      browserNotCompatible:
        'Cette application est optimisée pour Chrome. Vous pourriez rencontrer des dysfonctionnements avec ce navigateur',
      unavailableLine:
        'La ligne que vous avez sélectionnée n\'est pas disponible',
      noMicrophoneAccess: 'Wazo a besoin d\'accéder à votre microphone pour passer des appels',
      openSettings: 'Ouvrir les paramètres',
    },
    dashboard: {
      date: {
        today: "Aujourd'hui",
        yesterday: 'Hier',
      },
      recents: 'Récents',
      loadingRecents: 'Chargement des événements récents',
      callHistory: 'Appels',
      voicemails: 'Messages vocaux',
      chats: 'Messages texte',
      notifications: 'Notifications',
      emptyStateRecents:
        'Rechercher un contact et commencez une conversation avec celui-ci. Vous verrez vos activités récentes ici.',
      emptyStateCalls:
        'Initiez votre premier appel, vous le verrez apparaître ici.',
      emptyStateChats:
        'Discutez avec une personne, vous retrouverez la conversation ici',
      switchboards: 'POPC',
      incoming: 'Entrants',
      waiting: 'En attente',
      loadingHistory: "Chargement de l'historique",
      loadingChats: 'Chargement des messages texte',
      settings: 'Paramètres',
      mediaSettings: 'Paramètres media',
      lineSelection: 'Sélection de ligne',
      phonebook: 'Annuaire',
      callCenter: 'Centre d\'appel',
      profile: 'Mon profil',
      editProfile: 'Modifier mon profil',
      home: 'Accueil',
      debug: {
        reportBug: 'Rapporter un problème',
        reportBugTitle: 'Envoyer un rapport de bug',
        reportBugMessage: 'Message',
        reportBugValidate: 'Envoyer',
      },
    },
  },
  en: {
    banners: {
      doNotDisturbActive: '"Do Not Disturb" active',
      doNotDisturbActiveByline: 'Incoming calls will be sent to voicemail.',
      disableDoNotDisturb: 'Disable',
      redirectionsActive: 'Call redirection active',
      initialPermissionRequestP1: 'Wazo needs your permission to ',
      initialPermissionRequestP2: 'activate notifications',
      userDeniedPermissions:
        'For a better Wazo experience, we recommend that you enable browser notifications.',
      doNotAskAgainOnComputer: 'Never ask again on this computer',
      later: 'Later',
      activatePermissions: 'Activate permissions',
      webRtcDownStatus:
        'The telephony server seems unreachable. Wazo might not be able to make or receive any calls',
      websocketDownStatus: 'Wazo seems unable to receive any messages, information could not be updated automatically.',
      websocketDownRefresh: 'Try reconnecting',

      desktopUpdateAvailable: 'A new version {{ version }} is available.',
      desktopUpdateAvailableAction: 'Download',
      desktopUpdateDownloaded:
        'The new version {{ version }} is now ready to install',
      desktopUpdateDownloadedAction: 'Install and relaunch the application',
      desktopUpdateDownloading: 'Downloading new version: {{percent}}%',
      browserNotCompatible: 'This application is optimized for Chrome. You might have some issue with this browser',
      unavailableLine: 'The line you have selected is not available',
      noMicrophoneAccess: 'Wazo needs to access your microphone to make calls',
      openSettings: 'Open settings',
    },
    dashboard: {
      date: {
        today: 'Today',
        yesterday: 'Yesterday',
      },
      recents: 'Recents',
      loadingRecents: 'Loading recent events',
      callHistory: 'Calls',
      voicemails: 'Voicemails',
      chats: 'Chat Messages',
      notifications: 'Notifications',
      emptyStateRecents:
        'Find a contact and start a conversation. You will see your recent activity here.',
      emptyStateCalls: 'Place your first call, you will see it here.',
      emptyStateChats: 'Start chatting with someone, you will see it here.',
      switchboards: 'Switchboards',
      incoming: 'Incoming',
      waiting: 'Waiting',
      loadingHistory: 'Loading history',
      loadingChats: 'Loading text messages',
      settings: 'Settings',
      mediaSettings: 'Media Settings',
      lineSelection: 'Line Selection',
      phonebook: 'Phonebook',
      callCenter: 'Call Center',
      profile: 'My Profile',
      editProfile: 'Edit Profile',
      home: 'Homepage',
      debug: {
        reportBug: 'Report an issue',
        reportBugTitle: 'Send a bug report',
        reportBugMessage: 'Message',
        reportBugValidate: 'Send',
      },
    },
  },
};
