import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Settings } from 'luxon';

import translations from './translations';

const formatter = (value, format) => {
  if (format === 'uppercase') {
    return value.toUpperCase();
  }

  return value;
};

i18n.use(LanguageDetector).init({
  fallbackLng: 'en',
  debug: false,

  resources: translations,

  defaultNS: 'general',
  fallbackNS: 'common',

  interpolation: {
    escapeValue: false, // not needed for react
    format: formatter,
  },

  react: {
    defaultTransParent: 'span',
  },

  detection: {
    order: ['localStorage', 'navigator', 'htmlTag'],

    excludeCacheFor: ['cimode'],
  },

  simplifyPluralSuffix: true,
});

Settings.defaultLocale = i18n.language;

export default i18n;
