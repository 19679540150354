/* eslint-disable import/no-dynamic-require */
import { merge } from 'lodash';

import defaultAssets from '../../../public/whitelabel-default/assets.json';
import defaultColors from '../../../public/whitelabel-default/colors.json';
import defaultLabels from '../../../public/whitelabel-default/labels.json';

const DEFAULT_FOLDER = '/whitelabel-default/';

const isDesktop = !!window.electron;
const prefix = isDesktop ? '.' : '';
const files = ['assets', 'labels', 'colors', 'translations', 'css'];
const head = document.getElementsByTagName('head')[0];

export const fetchStoredWhitelabelValues = () => {
  const values = localStorage.getItem('whitelabelValues');
  if (!values) {
    return null;
  }

  try {
    return JSON.parse(values);
  } catch (_) {
    // Nothing to do
  }
  return null;
};

const whitelabelValues = fetchStoredWhitelabelValues() || {};

const defaultFiles = {
  assets: defaultAssets,
  colors: defaultColors,
  labels: defaultLabels,
};

const wrapPromise = promise => {
  let status = 'pending';
  let result;
  const suspender = promise.then(
    (r) => {
      status = 'success';
      result = r;
    },
    (e) => {
      status = 'error';
      result = e;
    },
  );

  return {
    read() {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        throw result;
      } else if (status === 'success') {
        return result;
      }

      return null;
    },
  };
};

const storeWhitelabelValues = (values: Object) => localStorage.setItem('whitelabelValues', JSON.stringify(values));

const onWhitelabelValuesFetched = results => {
  let hasError = false;
  files.forEach((file, idx) => {
    const defaultValues = defaultFiles?.[file];

    if (results[idx].status === 'fulfilled') {
      whitelabelValues[file] = defaultValues ? merge(defaultValues, results[idx].value) : results[idx].value;
    } else {
      hasError = true;
    }
  });

  if (hasError) {
    return null;
  }

  // Add absolute path to assets
  if (whitelabelValues?.assets) {
    Object.keys(whitelabelValues.assets).forEach(key => {
      const filePath = whitelabelValues.assets[key];
      if (filePath.indexOf(DEFAULT_FOLDER) === 0) {
        whitelabelValues.assets[key] = `${prefix}${filePath}`;
      } else {
        whitelabelValues.assets[key] = `${prefix}/whitelabel/assets/${filePath}`;
      }
    });
  }

  if (whitelabelValues.labels?.companyName) {
    document.title = `${whitelabelValues.labels.companyName} Web`;
  }

  // Add CSS
  if (whitelabelValues.css) {
    const previousStyle = document.getElementById('custom-css');
    if (previousStyle) {
      previousStyle.remove();
    }

    const style = document.createElement('style');
    style.id = 'custom-css';
    style.innerHTML = whitelabelValues.css;
    head.appendChild(style);
  }

  // Add favicon
  if (whitelabelValues.assets?.favicon) {
    const previousLink = document.querySelector('link[rel="shortcut icon"]');
    if (previousLink) {
      previousLink.remove();
    }

    const link = document.createElement('link');
    link.rel = 'shortcut icon';
    link.href = whitelabelValues.assets?.favicon;
    head.appendChild(link);
  }

  storeWhitelabelValues(whitelabelValues);

  return whitelabelValues;
};

const fetchFromFiles = () => Promise.allSettled([
  fetch(`${prefix}/whitelabel/assets.json`).then(res => res.json()),
  fetch(`${prefix}/whitelabel/labels.json`).then(res => res.json()),
  fetch(`${prefix}/whitelabel/colors.json`).then(res => res.json()),
  fetch(`${prefix}/whitelabel/translations.json`).then(res => res.json()),
  fetch(`${prefix}/whitelabel/styles.css`).then(res => res.text()),
]).then(onWhitelabelValuesFetched);

// fetchCb is a callback that is called when the fresh values are updated
export const fetchWhitelabelValues = (fetchCb: Function) => {
  const storedValues = fetchStoredWhitelabelValues();
  if (storedValues) {
    fetchFromFiles().then(fetchCb);

    return {
      read: () => storedValues,
    };
  }

  return wrapPromise(fetchFromFiles().then(result => {
    fetchCb();
    return result;
  }));
};

export const overrideTranslations = messages => merge(messages, whitelabelValues.translations);

export const overrideTheme = theme => merge(theme, whitelabelValues.colors);
