module.exports = {
  fr: {
    authentication: {
      forgotPassword: 'Mot de passe oublié ?',
      identifier: 'Identifiant',
      email: 'Courriel',
      password: 'Mot de passe',
      passwordConfirmation: 'Confirmation',
      refreshToken: 'Refresh token',
      clientId: 'Client ID',
      login: 'Connexion',
      sendEmail: 'Envoyer courriel',
      resetButton: 'Réinitialiser le mot de passe',
      resetPasswordMismatch: 'La confirmation ne correspond pas au mot de passe',
      resetPasswordSuccess: 'Votre mot de passe a correctement été réinitialisé',
      resetPasswordSent: 'Un courriel avec les informations de réinitialisation vous a été envoyé',
      resetPasswordFailure: 'Une erreur est survenue lors de la réinitialisation de votre mot de passe',
      resetPasswordNotFound: 'Aucun utilisateur avec ce courriel a été trouvé',
      passwordTooShort: 'Votre mot de passe ne doit pas être vide',
      back: 'Précédent',
      backFromReset: 'Retour à l\'authentification',
      next: 'Suivant',
      previous: 'Précédent',
      missingMandatory: '* Tous les champs sont obligatoires',
      unauthorized: '* Ce compte est introuvable',
      invalidServer: 'Merci de saisir un serveur valide',
      serverConnectionFailed:
        'Impossible de contacter le serveur, veuillez vérifier son nom ainsi que votre connexion internet.',
      prefilledServerConnectionFailed:
        'Impossible de contacter le serveur, veuillez vérifier votre connexion internet.',
      unavailableServer:
        'Le serveur que vous tentez de joindre est actuellement indisponible.',
      unavailableServerSslMessage: 'Si le serveur est correctement saisi, merci d\'',
      unavailableServerSslLink: 'accepter le certificat https',
      resetDomainExplanation: 'Vous devez définir un domaine afin de pouvoir réinitialiser votre mot de passe',
      unknownUser: 'Nous ne reconnaissons pas votre nom d\'utilisateur ou votre mot de passe',
      serverAddress: 'Adresse du serveur',
      organizationDomain: 'Domaine de l\'organisation',
      connection: 'Connexion',
      ldapConnection: 'Connexion avec LDAP',
      samlConnection: 'Connexion avec un compte d\'entreprise',
      noSamlRouteError: 'Ce serveur n\'est pas configuré pour utiliser l\'authentification SAML',
      samlConfigError: 'Une erreur est survenue, assurez-vous que la configuration SAML fournie est correcte',
      samlLogoutError: 'Une erreur est survenue lors de la déconnexion à votre fournisseur d\'identité.',
      domainName: 'Nom du domaine LDAP',
      noDomainName: 'Un nom de domaine LDAP doit être fourni pour se connecter',
      wrongDomainName: 'Impossible de trouver un domaine LDAP correspondant, veuillez vérifier son nom',
      unknownAuthentifactionError: 'Erreur d\'authentification inconnue',
      invalidToken: 'Votre session n\'est plus valide, merci de vous authentifier à nouveau',
      myProfile: 'Mon profil',
      loading: 'Chargement en cours',
    },
  },
  en: {
    authentication: {
      forgotPassword: 'Forgot password ?',
      identifier: 'Username',
      email: 'Email',
      password: 'Password',
      passwordConfirmation: 'Confirmation',
      refreshToken: 'Refresh token',
      clientId: 'Client ID',
      login: 'Login',
      sendEmail: 'Send email',
      resetButton: 'Reset password',
      resetPasswordMismatch: 'Confirmation doesn\'t match password',
      resetPasswordSuccess: 'Your password has successfully been reset',
      resetPasswordSent: 'An email with the reset information has been sent',
      resetPasswordFailure: 'An error occurred during your password reset',
      resetPasswordNotFound: 'No account linked to this email',
      passwordTooShort: 'Your password can\'t be empty',
      back: 'Back',
      backFromReset: 'Back to login',
      next: 'Next',
      previous: 'Previous',
      missingMandatory: '* All the fields are mandatory',
      unauthorized: '* Unauthorized',
      invalidServer: 'Please use a valid server name',
      serverConnectionFailed: 'Couldn\'t reach server, please verify its name and your internet connection.',
      prefilledServerConnectionFailed: 'Couldn\'t reach server, please verify your internet connection.',
      unavailableServer: 'The server you are trying to reach is currently unavailable.',
      unavailableServerSslMessage: 'If the server name is correct, please',
      unavailableServerSslLink: 'accept the https certificate',
      resetDomainExplanation: 'You have to define a domain before resetting your password.',
      unknownUser: 'We couldn\'t find your username or password',
      serverAddress: 'Server address',
      organizationDomain: 'Organization domain',
      connection: 'Log in',
      ldapConnection: 'Log in with LDAP',
      samlConnection: 'Log in with company account',
      noSamlRouteError: 'This server is not configured to use SAML authentication',
      samlConfigError: 'An error occured, make sure the provided SAML configuration is correct',
      samlLogoutError: 'There was an error logging out of your identity provider.',
      domainName: 'LDAP domain name',
      noDomainName: 'A domain name must be provided to log in with LDAP',
      wrongDomainName: 'Couldn\'t find a matching LDAP domain, please verify its name',
      unknownAuthentifactionError: 'Unknown authentication error',
      invalidToken: 'Your session is no longer valid, please sign in again',
      myProfile: 'My profile',
      loading: 'Loading',
    },
  },
};
