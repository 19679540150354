module.exports = {
  fr: {
    agent: {
      login: 'Connexion',
      logout: 'Déconnexion',
      pause: 'Pause',
      resume: 'Reprendre',
      noCustomerRelationshipRights: 'Licence utilisateur non compatible',
    },
  },
  en: {
    agent: {
      login: 'Login',
      logout: 'Logout',
      pause: 'Pause',
      resume: 'Resume',
      noCustomerRelationshipRights: 'Incompatible user license',
    },
  },
};
