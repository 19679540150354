module.exports = {
  fr: {
    settings: {
      integration: 'Intégrations',
      integrationConnect: 'Se connecter à {{store}}',
      integrationDisconnect: 'Déconnecter de {{store}}',
      connectTo: 'Connecter',
      disconnectFrom: 'Déconnecter',
      callerId: 'Identifiant d\'appelant',
      outgoingCalls: 'Appels sortants',
      defaultCallerId: 'Identifiant par défaut',
      integrations: 'Intégrations',
      language: 'Langue',
      testSpeakers: 'Test',
      connectionsStatusMessage: 'Les indicateurs se mettent à jour automatiquement',
      webRTC: 'WebRTC',
      websocket: 'Websocket',
      keyboardShortcut: 'Raccourcis clavier',
      shortcut: {
        enabled: 'Actifs',
        answerIncomingCall: 'Décrocher',
        answerHeldCall: 'Décrocher un appel en attente',
        muteOngoingCall: 'Activer / Désactiver micro',
        holdOngoingCall: 'Activer / Désactiver attente',
        transfer: 'Transférer',
        hangup: 'Raccrocher',
        info: 'Les raccourcis sont éditables. Cliquer sur l\'un d\'eux pour modifier la touche de raccourci',
      },
      shortcutErrors: {
        unauthorised: 'La touche {{key}} ne peut pas être utilisée comme raccourcis.',
        alreadyUsed: 'La touche {{key}} est déjà utilisée.',
      },
      notifications: {
        soundNotifications: 'Notifications auditives',
        none: 'Aucune notification',
        noneInfo:
          'Vous ne recevrez pas de notifications mais pouvez visualiser les évènements non lus représentés à l\'aide de badges',
        partial: 'Partiel',
        partialInfo: 'Vous recevrez des notifications sous forme de bannière à l\'intérieur de l\'application',
        total: 'Total',
        totalInfo: 'Vous recevrez des notifications en tout temps, à l\'intérieur et en dehors de l\'application',
        muteInfo: 'Vous serez alerté avec un son lors de la réception d\'un message ou lorsqu\'une personne vous appelle',
        permissionDeniedInfo:
          'Cette option est indisponible car vous avez refusé les permissions du navigateur pour recevoir des notifications',
        notifications: 'Notifications',
        emergencyCallWarning: {
          title: 'Cette application n\'est PAS optimisée pour les appels d\'urgence',
          description: 'Vos appels ne sont pas localisés',
          disable: 'Désactiver',
          discard: 'Fermer',
          settings: {
            title: 'Avertissement de l\'appel d\'urgence',
          },
        },
        deepLinkPortAlreadyUsed: {
          title: 'Impossible de démarrer le serveur d\'écoute d\'événement d\'appel local. Merci de contacter votre administrateur système.',
          discard: 'Fermer',
        },
        animation: {
          settings: {
            title: 'Activer les animations',
          },
        },
        callWindow: {
          displayFocused: 'Toujours afficher la fenêtre d\'appel',
          displayFocusedSub: 'La fenêtre d\'appel sera affichée même lorsque l\'application est active',
        },
        memory: {
          showWarning: 'Alerter la consommation mémoire',
        },
      },
      profile: {
        label: 'Profil',
        extension: 'Poste',
        incall: 'Direct',
      },
      passwordEdit: 'Édition de mot de passe',
      about: {
        aboutLabel: 'À propos',
        licenses: 'Licences',
        debug: 'Débogage',
        applicationVersion: 'Version {{version}}',
        subscriptionType: {
          label: 'Abonnement: {{subscription}}',
          voice: 'Voix',
          unifiedCommunication: 'Communication unifiée',
          collaboration: 'Collaboration',
          contactCenter: 'Centre de contacts',
        },
        domain: 'Domaine: {{server}}',
        engine: 'Moteur: {{engine}}',
        whatsNew: 'Nouveautés',
        howTo: 'Configuration',
        poweredBy: 'Propulsé par Wazo',
        poweredByUrl: 'https://wazo.io/',
        whatsNewUrl: 'https://support.wazo.io/hc/fr-fr/sections/360009464052-E-UC-Apps-Wazo-Web-Desktop',
        howToUrl: 'https://support.wazo.io/hc/fr-fr/articles/360046348851--Wazo-Web-Wazo-Desktop-D%C3%A9couvrez-l-application',
      },
      debug: {
        everythingInConsole: 'Toutes les informations de débogage sont affichées dans la console Javascript. Pour y accéder dans Chrome: Affichage > Développeur > Outils pour développeur > ouvrir l\'onglet console.',
        startCheck: 'Vérification de la stack',
        startCheckSub: 'Appelera *10 automatiquement',
        enableReporter: 'Activer le rapport d\'erreur',
        requiresRelog: 'Nécessite une réauthentification',
        disableReporter: 'Désactiver le rapport d\'erreur',
        copyIssue: 'Copier le rapport d\'erreur',
      },
      nonWebRTCLine: 'La ligne active n\'utilise pas WebRTC',
      noIntegrationMain: 'Aucune integration externe n\'est disponible.',
      noIntegrationSub: 'Veuillez contacter votre administrateur pour accéder à cette fonctionnalité.',
      noMicrosoftIntegration: 'L\'intégration Office 365 n\'est pas disponible. Veuillez contacter votre administrateur pour accéder à cette fonctionnalité.',
      noGoogleIntegration: 'L\'intégration Google n\'est pas disponible. Veuillez contacter votre administrateur pour accéder à cette fonctionnalité.',
      downloadApp: 'Installer l\'application',
      downloadAppUrl: 'https://wazo.io/download/',
      updateApp: 'Mettre à jour l\'application',
      switchboard: {
        label: 'POPC',
        notifyActive: 'Notification POPC',
        notifyActiveInfo: 'Vous serez alerté par un son pour tous les appels entrants du POPC',
        notifyFirstCallOnly: 'Notifier le premier appel seulement',
        notifyFirstCallOnlyInfo: 'Vous serez alerté par un son exclusivement lorsque une file d\'attente vide du POPC reçoit un nouvel appel',
        notifyWhileIncall: 'Notifier durant les appels',
        notifyWhileIncallInfo: 'Vous serez alerté par un son durant vos appels',
        reminder: 'Notifier en continu les appels entrants',
        reminderDetails: 'Vous serez alerté toutes les {{timeout}} secondes d\'un appel entrant par un son',
        useFullRingtone: 'Utiliser la sonnerie complète',
        useFullRingtoneInfo: 'Permet l\'utilisation d\'une sonnerie standard au lieu d\'un bip sur les appels entrants POPC',
        options: 'Configurations POPC',
      },
      phonebooks: {
        label: 'Annuaires',
        reorder: 'Selectionnez et réordonnez les annuaires affichés',
      },
      fullscreenEnter: 'Plein écran',
      fullscreenExit: 'Sortir du plein écran',
      ramUsageWarning: 'La mémoire disponible sur votre ordinateur est trop faible',
      ramUsageWarningDetails: 'Votre ordinateur consomme {{usage}}% de mémoire, Wazo Desktop risque de subir des lenteurs',
      customized: 'Personnalisé...',
      audioSimplified: 'Audio',
      loading: 'Chargement en cours...',
      join: 'Rejoindre',
      joinConference: 'Rejoindre la conférence',
      hid: {
        selectDevice: 'Sélectionner un casque',
        connectHeadset: 'Activer le contrôle de casque',
        disconnectHeadset: 'Désactiver le contrôle de casque',
        connecting: 'Connexion en cours...',
        disconnecting: 'Déconnexion en cours...',
        disabledWhileInCall: 'Cette fonctionnalité est désactivé durant les appels',
        connectedTo: 'Connecté à «{{productName}}»',
        noHidDevices: 'Aucun casque disponible',
        info: 'Établit un lien avec votre casque, permettant d\'opérer l\'application à partir de celui-ci -- répondre aux appels, activer et désactiver le son, ... Requiert un casque capable d\'utiliser le protocol WebHID',
        label: 'Contrôle du casque',
        enabled: 'Contrôles du casque activé',
        enable: 'Activer les contrôles du casque',
        status: {
          success: 'Votre casque est compatible avec notre ensemble de fonctionnalités',
          warning: 'Votre casque est partiellement compatible avec notre ensemble de fonctionnalités',
          error: 'Votre casque est incapable d\'utiliser nos fonctionnalités de téléphonie',
        },
        remove: {
          title: 'Êtes-vous bien certain de vouloir déconnecter le casque ?',
          description: 'Notez que l\'application redémarrera suite à cette action.',
          button: 'Déconnecter',
        },
        reboot: 'L\'application sera redémarrée suite à la sélection',
        rebooting: 'Redémarrage en cours',
        nonCompliant: {
          title: 'Appareil non-compatible',
          description: 'Vous ne pouvez utiliser «{{deviceName}}» avec cette application',
        },
        workbench: 'WebHID',
        workbenchSub: 'Accéder au panneau de débogage',
        capabilities: {
          header: 'Capacités du casque',
          hook: 'Décrocher / raccrocher',
          mute: 'Mettre en sourdine',
          hold: 'Mettre en attente',
          reject: 'Refuser',
        },
      },
    },
  },
  en: {
    settings: {
      integration: 'Integrations',
      integrationConnect: 'Connect to {{store}}',
      integrationDisconnect: 'Disconnect from {{store}}',
      connectTo: 'Connect',
      disconnectFrom: 'Disconnect',
      callerId: 'Caller ID',
      outgoingCalls: 'Outgoing calls',
      defaultCallerId: 'Default caller ID',
      integrations: 'Integrations',
      language: 'Language',
      testSpeakers: 'Test',
      connectionsStatusMessage: 'Indicators will automatically be updated if a problem with your connections occur',
      webRTC: 'WebRTC',
      websocket: 'Websocket',
      keyboardShortcut: 'Keyboard shortcuts',
      shortcut: {
        enabled: 'Enabled',
        answerIncomingCall: 'Answer',
        answerHeldCall: 'Answer held call',
        muteOngoingCall: 'Mute / Unmute',
        holdOngoingCall: 'Hold / Unhold',
        transfer: 'Transfer',
        hangup: 'Hangup',
        info: 'Shortcuts are editable. Click on one and type a key to change it',
      },
      shortcutErrors: {
        unauthorised: 'The {{key}} key can\'t be used as shortcuts.',
        alreadyUsed: 'The {{key}} key is already used.',
      },
      notifications: {
        soundNotifications: 'Sound notifications',
        none: 'No notification',
        noneInfo: 'You won\'t receive any notifications but you will be able alerted of new events by badges',
        partial: 'Partial',
        partialInfo: 'You will only receive toast notifications inside the application',
        total: 'Total',
        totalInfo: 'You will receive notifications at all times, inside and outside the application',
        muteInfo: 'You will hear a sound when you receive a message or when somebody calls you',
        permissionDeniedInfo:
          'This option is disabled because you have denied the browser permissions for notifications',
        notifications: 'Notifications',
        emergencyCallWarning: {
          title: 'This application is NOT optimized for emergency calls',
          description: 'Your calls cannot be located',
          disable: 'Disable',
          discard: 'Close',
          settings: {
            title: 'Emergency Call Warning',
          },
        },
        deepLinkPortAlreadyUsed: {
          title: 'Unable to start the local call listening server. Please contact your system administrator.',
          discard: 'Close',
        },
        animation: {
          settings: {
            title: 'Enable animations',
          },
        },
        callWindow: {
          displayFocused: 'Always display call popup',
          displayFocusedSub: 'The call popup will display even if your application is active',
        },
        memory: {
          showWarning: 'Alert when memory usage is too high',
        },
      },
      profile: {
        label: 'Profile',
        extension: 'Extension',
        incall: 'Direct',
      },
      passwordEdit: 'Password edit',
      about: {
        aboutLabel: 'About',
        licenses: 'Licenses',
        debug: 'Debug',
        applicationVersion: 'Version {{version}}',
        subscriptionType: {
          label: 'Subscription: {{subscription}}',
          voice: 'Voice',
          unifiedCommunication: 'Unified communication',
          collaboration: 'Collaboration',
          contactCenter: 'Contact center',
        },
        domain: 'Domain: {{server}}',
        engine: 'Engine: {{engine}}',
        whatsNew: 'What\'s new',
        howTo: 'How to',
        poweredBy: 'Powered by Wazo',
        poweredByUrl: 'https://wazo.io/',
        whatsNewUrl: 'https://support.wazo.io/hc/fr-fr/sections/360009464052-E-UC-Apps-Wazo-Web-Desktop',
        howToUrl: 'https://support.wazo.io/hc/fr-fr/articles/360046348851--Wazo-Web-Wazo-Desktop-D%C3%A9couvrez-l-application',
      },
      debug: {
        everythingInConsole: 'All the debug information are displayed in the Javascript console. You can see them by opening the Chrome dev tool in View > Developer > Developer Tools > open the console tab',
        startCheck: 'Stack check',
        startCheckSub: 'Will make a call to *10 automatically',
        enableReporter: 'Enable bug report',
        requiresRelog: 'Requires a relog',
        disableReporter: 'Disable bug report',
        copyIssue: 'Copy bug report in clipboard',
      },
      nonWebRTCLine: 'Active line does not use WebRTC',
      noIntegrationMain: 'No external integration available',
      noIntegrationSub: 'Please contact your administration to access this functionnality',
      noMicrosoftIntegration: 'Office 365 integration not available. Please contact your administration to access this functionality',
      noGoogleIntegration: 'Google integration not available. Please contact your administration to access this functionality',
      downloadApp: 'Download application',
      downloadAppUrl: 'https://wazo.io/download/',
      updateApp: 'Update application',
      switchboard: {
        label: 'Switchboard',
        notifyActive: 'Switchboard Notification',
        notifyActiveInfo: 'You will hear a sound notification for new incoming switchboard calls',
        notifyFirstCallOnly: 'Notify first call only',
        notifyFirstCallOnlyInfo: 'You will be notified only when the switchboard queue is not empty anymore. Set this parameter to off will notify you of all switchboard incoming calls',
        notifyWhileIncall: 'Notify while in call',
        notifyWhileIncallInfo: 'You will be notified during a conversation',
        reminder: 'Continuously notify incoming calls',
        reminderDetails: 'You will be reminded for every {{timeout}} seconds of an incoming call with a sound',
        useFullRingtone: 'Use ringtone',
        useFullRingtoneInfo: 'Allows to use a standard ringtone instead of a beep on incoming switchboard calls',
        options: 'Switchboard settings',
      },
      phonebooks: {
        label: 'Phonebooks',
        reorder: 'Select and reorder displayed phonebooks',
      },
      fullscreenEnter: 'Fullscreen',
      fullscreenExit: 'Exit fullscreen',
      ramUsageWarning: 'The memory available on your computer is too low',
      ramUsageWarningDetails: 'Your computer is using {{usage}}% of its memory, Wazo Desktop may be slow',
      customized: 'Customized...',
      audioSimplified: 'Audio',
      loading: 'Loading...',
      join: 'Join',
      joinConference: 'Join the conference',
      hid: {
        selectDevice: 'Select headset',
        connectHeadset: 'Activate headset controls',
        disconnectHeadset: 'Disconnect headset controls',
        connecting: 'Connecting...',
        disconnecting: 'Disconnecting...',
        disabledWhileInCall: 'This feature is disabled while in-call',
        connectedTo: 'Connected to {{productName}}',
        noHidDevices: 'No available headset',
        info: 'Establishes a link with your headset, allowing you to operate this app from it -- i.e.: answer calls, mute and unmute, ... Requires a WebHID-compliant device.',
        label: 'Headset Controls',
        enabled: 'Headset controls enabled',
        enable: 'Enable headset controls',
        status: {
          success: 'Your headset is fully compliant with our feature set',
          warning: 'Your headset is partially compliant with our feature set',
          error: 'Your headset is unable to use our telephony feature set',
        },
        remove: {
          title: 'Are you sure you want to remove the selected headset?',
          description: 'The application will reload a few seconds following this action.',
          button: 'Remove',
        },
        reboot: 'Note: the application will reload following device selection',
        rebooting: 'Reloading',
        nonCompliant: {
          title: 'Non-compliant device',
          description: 'You can\'t use "{{deviceName}}" with this application',
        },
        workbench: 'WebHID',
        workbenchSub: 'Access debugging workbench',
        capabilities: {
          header: 'Headset capabilities',
          hook: 'Answer / hang up',
          mute: 'Mute / unmute',
          hold: 'Hold / resume',
          reject: 'Reject',
        },
      },
    },
  },
};
