module.exports = {
  fr: {
    faxes: {
      addDocument: 'Ajouter un document',
      cancel: 'Annuler',
      faxNumber: 'Numéro de fax',
      noFaxNumber: 'Il n\'y a aucun numéro de fax attribué à ce contact',
      send: 'Envoyer',
      sendFax: 'Envoyer un fax',
      sendAnotherFax: 'Envoyer un autre fax',
      sentSuccessfully:
        'Votre fax "{{filename}}" a été envoyé avec succès au {{destination}}. Vous allez recevoir sous peu un accusé de réception.',
      faxSent: 'Fax envoyé!',
      faxError: 'Erreur lors de l\'envoi.',
      faxErrorMessage: 'Veuillez réssayer plus tard.',
      receptionConfirmedTitle: 'Fax - Accusé de réception',
      receptionConfirmedMessage: '"{{file}}" envoyé à {{destination}}',
      receptionFailedTitle: 'Fax - Erreur de réception',
      receptionFailedMessage: '"{{file}}" envoyé à {{destination}}',
    },
  },
  en: {
    faxes: {
      addDocument: 'Add document',
      cancel: 'Cancel',
      faxNumber: 'Fax number',
      noFaxNumber: 'This contact doesn\'t have a fax number',
      send: 'Send',
      sendFax: 'Send a fax',
      sendAnotherFax: 'Send another fax',
      sentSuccessfully:
        'Your fax "{{filename}}" has been sent successfully to {{destination}}. You will receive an acknowledgment of receipt shortly.',
      faxSent: 'Fax sent!',
      faxError: 'An error occured',
      faxErrorMessage: 'Please try again later.',
      receptionConfirmedTitle: 'Fax - Acknowledgment received',
      receptionConfirmedMessage: '"{{file}}" sent to {{destination}}',
      receptionFailedTitle: 'Fax - Reception error',
      receptionFailedMessage: '"{{file}}" not sent to {{destination}}',
    },
  },
};
