import merge from 'deepmerge';

import activities from '../../activities/i18n';
import agent from '../../agent/i18n';
import authentication from '../../authentication/i18n';
import calls from '../../calls/i18n';
import contacts from '../../contacts/i18n';
import dashboard from '../../dashboard/i18n';
import faxes from '../../faxes/i18n';
import meetings from '../../meetings/i18n';
import notifications from '../../notifications/i18n';
import search from '../../search/i18n';
import settings from '../../settings/i18n';
import callsShared from '../../shared/calls/i18n';
import shared from '../../shared/main/i18n';
import settingsShared from '../../shared/settings/i18n';
import videoconf from '../../shared/videoconf/i18n';
import switchboard from '../../switchboard/i18n';
import voicemails from '../../voicemails/i18n';
import { overrideTranslations } from '../whitelabel';

import general from './general';

export default overrideTranslations(merge.all([
  activities,
  authentication,
  calls,
  callsShared,
  contacts,
  dashboard,
  faxes,
  general,
  search,
  settings,
  settingsShared,
  switchboard,
  voicemails,
  agent,
  notifications,
  meetings,
  videoconf,
  shared,
]));
