module.exports = {
  fr: {
    notifications: {
      yes: 'Oui',
      no: 'Non',
      notificationUserName: 'Jean Dupont',
      notificationMessage: 'Salut ! Ca va bien ?',
    },
  },
  en: {
    notifications: {
      yes: 'Yes',
      no: 'No',
      notificationUserName: 'John Doe',
      notificationMessage: 'Hi! How are you?',
    },
  },
};
