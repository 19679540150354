module.exports = {
  fr: {
    videoconf: {
      pin: 'Épingler',
      unpin: 'Détacher',
      lowerHand: 'Baisser la main',
      raiseHand: 'J\'ai une question',
      stopScreensharing: 'Stopper le partage d\'écran',
      startScreensharing: 'Partager mon écran',
      unmuteAudio: 'Réactiver le son',
      muteAudio: 'Muet',
      unmuteVideo: 'Réactiver la caméra',
      muteVideo: 'Désactiver la caméra',
      showVideo: 'Afficher le retour video',
      chat: 'Messages texte',
      participants: 'Participants',
      audioOnly: 'Vous êtes connecté en mode «audio»',
      enteringRoom: 'Entrée dans la conférence...',
      alone: 'Il n\'y a personne dans la conférence',
      objectFitCover: 'Remplir le cadre',
      objectFitContain: 'Ajuster au cadre',
      upgradeToVideo: 'Activer la caméra',
      downgradeToAudio: 'Désactiver la caméra',
      ban: 'Exclure',
      meetingInfo: 'Informations sur la réunion',
      close: 'Fermer',
      enterRoom: 'Entrer dans la chambre',
      roomName: 'Nom de la chambre',
      number: 'Numéro',
      elapsedTime: 'Temps écoulé',
      guest: 'Invité',
      floatingToolbar: 'Menu flottant',
      typeMessage: 'Écrivez votre message...',
      date: {
        today: 'Aujourd\'hui',
        yesterday: 'Hier',
      },
      startChatting: 'Démarrer une conversation',
      loadingDevices: 'Chargement des périphériques...',
      you: 'Vous',
      others: 'autres',
      disableCamera: 'Désactiver la camera',
      enableLowDataMode: 'Activer le mode «Faibles données»',
      audioOnlyMain: 'Vous ne pourrez pas activer votre caméra et ne verrez pas les caméras des autres participants, ni les partages d\'écran.',
      audioOnlySecondary: 'Vous devrez sortir de la réunion pour désactiver cette option.',
      lowDataMode: 'Mode faibles données',
      disabledCameraWarningPrimary: 'Votre caméra sera désactivée.',
      disabledCameraWarningSecondary: 'Vous ne verrez pas les autres participants.',
      disabledVideoPermission: 'Les appels vidéos sont désactivés',
      confirmBan: 'Êtes-vous sûr de vouloir exclure «{{name}}» de la réunion ?',
      banError: 'Une erreur est survenue en tentant d\'exclure «{{name}}»',
    },
  },
  en: {
    videoconf: {
      pin: 'Pin',
      unpin: 'Unpin',
      lowerHand: 'Lower hand',
      raiseHand: 'I have a question',
      stopScreensharing: 'Stop screensharing',
      startScreensharing: 'Share screen',
      unmuteAudio: 'Unmute',
      muteAudio: 'Mute',
      unmuteVideo: 'Turn on camera',
      muteVideo: 'Turn off camera',
      showVideo: 'Restore',
      chat: 'Chat',
      participants: 'Participants',
      audioOnly: 'You are in audio-only mode',
      enteringRoom: 'Entering the room...',
      alone: 'There is no one else in the room',
      objectFitCover: 'Full frame',
      objectFitContain: 'Fit to frame',
      upgradeToVideo: 'Enable camera',
      downgradeToAudio: 'Disable camera',
      ban: 'Kick',
      meetingInfo: 'Meeting Info',
      close: 'Close',
      enterRoom: 'Enter conference room',
      roomName: 'Room Name',
      number: 'Number',
      elapsedTime: 'Elapsed Time',
      guest: 'Guest',
      floatingToolbar: 'Floating Menu',
      typeMessage: 'Enter a message...',
      date: {
        today: 'Today',
        yesterday: 'Yesterday',
      },
      startChatting: 'Start chatting',
      loadingDevices: 'Loading devices...',
      you: 'You',
      others: 'others',
      disableCamera: 'Disable camera',
      enableLowDataMode: 'Enable low-data mode',
      audioOnlyMain: 'You will not be able to turn on your camera, see other participants and use screen sharing.',
      audioOnlySecondary: 'You will have to leave the meeting to change this option.',
      lowDataMode: 'Low-data mode',
      disabledCameraWarningPrimary: 'Your camera will be disabled.',
      disabledCameraWarningSecondary: 'You will not see other participants.',
      disabledVideoPermission: 'Video calls are disabled',
      confirmBan: 'Are you sure you want to kick "{{name}}" from the meeting ?',
      banError: 'There was an error while attempting to kick "{{name}}"',
    },
  },
};
