module.exports = {
  fr: {
    calls: {
      incomingCall: 'Appel entrant',
      incomingSwitchboardCall: 'Appel POPC entrant',
      incomingCallLog: 'Appel reçu',
      incomingCallDevice: 'sur la ligne',
      whoToCall: 'Qui voulez-vous joindre ?',
      enterNameOrNumber: 'Entrez un nom ou un numéro',
      cancelTransfer: 'Annuler le transfert',
      noResult: 'Aucun résultat pour "{{query}}".',
      unknown: 'Inconnu',
      outgoingCall: 'Appel émis',
      missedOutgoingCall: 'Appel annulé',
      missedCall: 'Appel manqué',
      declinedCall: 'Appel refusé',
      adHocConference: 'Conférence',
      merge: 'Fusionner',
      mergeToOngoing: 'Ajouter à la conférence',
      cannotMergeVideoCall: 'Impossible de fusionner un appel vidéo',
      alreadyInConference: 'Impossible d\'avoir plus d\'une conférence active',
      cancel: 'Annuler',
      all: 'Tous',
      mergeTheseCalls: 'Fusionner les appels',
      callTransfer: 'Transfert d\'appel',
      direct: 'Direct',
      indirect: 'Indirect',
      confirmIndirectTransfer: 'Confirmer le transfert',
      cancelIndirectTransfer: 'Annuler le transfert',
      transferTo: 'Transfert d\'appel vers',
      dialer: 'Composeur',
      conferenceRoom: 'Conférence ({{number}})',
      participants: 'Participants',
      deviceMissingTitle: 'Problème avec l\'appel !',
      deviceMissingText: 'Vérifier la disponibilité de vos périphériques audio.',
      noContactFound: 'Aucun contact trouvé',
      retrieveCall: 'Récuperer',
      hangupCall: 'Raccrocher',
      convergeFailure: 'Impossible de récupérer l\'appel',
      initializing: 'Initialisation&nbsp;...',
      transferThisNumber: 'Transférer à "{{number}}"',
      mute: 'Muet',
      unmute: 'Réactiver le son',
      hold: 'En attente',
      resume: 'Reprendre',
      transfer: 'Transférer',
      addPeople: 'Ajouter un participant',
      startRecording: 'Enregistrer',
      stopRecording: 'Arrêter l\'enregistrement',
      minSearchTermLength: 'Minimum de % lettres',
      calls: 'Appels',
      participantJoinedRoom: '{{participant}} a rejoint la conférence',
      participantLeftRoom: '{{participant}} a quitté',
      answeredFromHeadset: 'Appel avec {{name}} décroché par casque',
      hungUpFromHeadset: 'Appel avec {{name}} raccroché par casque',
      rejectedFromHeadset: 'Appel avec {{name}} a été rejeté par casque',
      ctiCallError: 'Erreur lors de l\'appel à {{number}}.',
      ctiCallErrorDetails: 'Assurez-vous que votre téléphone est connecté et configuré.',
      webRtcCallError: 'Erreur lors de l\'appel à {{number}}.',
      webRtcOutgoingCallErrorDetails: 'Impossible de passer l\'appel dû à une erreur technique.',
      webRtcIncomingCallErrorDetails: 'Impossible de décrocher l\'appel dû à une erreur technique.',
      callAnsweredElsewhere: 'L\'appel {{number}} a été décroché sur un autre poste.',
      callAnsweredElsewhereDetails: 'Un autre poste vient de décrocher l\'appel.',
      answerCallError: 'L\'appel de {{number}} n\'existe plus, il n\'a pas pu être répondu.',
      answerCallErrorDetails: 'L\'appel a probablement été raccroché ou décroché par un autre poste.',
      onHold: 'En attente',
      chat: 'Message texte',
      ongoingCalls: 'Vous avez des appels en cours',
      unablePlayRingSound: 'Impossible de sonner pour l\'appel provenant de {{ number }}',
      upgradeToVideo: '{{ displayName }} souhaite passer en video',
      upgradeToVideoDetails: 'Voulez-vous activer votre caméra ?',
      recorder: {
        recording: 'En cours...',
        paused: 'Pause',
        record: 'Enregistrer',
        loading: 'Chargement...',
      },
      meetingWith: 'Réunion avec {{name}}',
      mediaSettings: 'Paramètres média',
      audioOnly: 'Audio seulement',
      screenshareSelectionTitle: 'Choisissez l\'écran à partager',
      screen: 'Écran complet',
      window: 'Fenêtre',
      joinConference: 'Participer',
      roomConnectionErrorTitle: 'Erreur de connexion à la conférence',
      roomConnectionErrorMessage: 'Message: {{error}}',
      sfuErrorTitle: 'Erreur de configuration SFU',
      sfuErrorMessage: 'Veuillez contactez votre administrateur',
      router: {
        joining: 'Rejoindre "{{name}}"',
        callingContact: 'Appel vers {{name}} ({{number}})',
        callingNumber: 'Appel vers le numéro «{{number}}»',
        uncallableNumber: 'Impossible d\'appeler ce numéro',
        routingCall: 'Routage d\'appel en cours',
        settingUpPhone: 'Configuration',
        notMeetingOwner: 'Vous n\'êtes pas un gestionnaire de ce meeting. Veuillez utiliser Wazo Meet.',
      },
      transferType: 'Type de transfert',
      focusOnCallWindow: 'Vers la fenêtre d\'appel',
      focusOnMainApp: 'Vers l\'application principale',
      anonymous: 'Anonyme',
      default: 'Prédéterminé',
      callerId: 'Identifiant d\'appelant',
    },
  },
  en: {
    calls: {
      incomingCall: 'Incoming call',
      incomingSwitchboardCall: 'Incoming switchboard call',
      incomingCallLog: 'Incoming call',
      incomingCallDevice: 'on line',
      whoToCall: 'Who would you like to call?',
      enterNameOrNumber: 'Enter a name or number',
      cancelTransfer: 'Cancel transfer',
      noResult: 'No results for "{{query}}".',
      unknown: 'Unknown',
      outgoingCall: 'Outgoing call',
      missedOutgoingCall: 'Canceled call',
      missedCall: 'Missed call',
      declinedCall: 'Declined call',
      adHocConference: 'Conference',
      merge: 'Merge',
      mergeToOngoing: 'Add to conference',
      cannotMergeVideoCall: 'Cannot merge a video call',
      alreadyInConference: 'Cannot have more than one active conference',
      cancel: 'Cancel',
      all: 'All',
      mergeTheseCalls: 'Merge these calls',
      callTransfer: 'Call transfer',
      direct: 'Direct',
      indirect: 'Indirect',
      confirmIndirectTransfer: 'Confirm transfer',
      cancelIndirectTransfer: 'Cancel transfer',
      transferTo: 'Transfer to',
      dialer: 'Dialer',
      conferenceRoom: 'Conference ({{number}})',
      participants: 'Participants',
      deviceMissingTitle: 'Issue with the call !',
      deviceMissingText: 'Check that your audio devices are available.',
      noContactFound: 'No contact found',
      retrieveCall: 'Retrieve',
      hangupCall: 'Hangup',
      convergeFailure: 'Unable to retrieve call',
      initializing: 'Initializing&nbsp;...',
      transferThisNumber: 'Transfer to "{{number}}"',
      mute: 'Mute',
      unmute: 'Unmute',
      hold: 'Hold',
      resume: 'Resume',
      transfer: 'Transfer',
      addPeople: 'Add people',
      startRecording: 'Record',
      stopRecording: 'Stop recording',
      minSearchTermLength: 'Minimum of % characters',
      calls: 'Calls',
      participantJoinedRoom: '{{participant}} joined',
      participantLeftRoom: '{{participant}} left',
      answeredFromHeadset: 'Call with {{name}} answered with headset',
      hungUpFromHeadset: 'Call with {{name}} hung up from headset',
      rejectedFromHeadset: 'Call with {{name}} rejected from headset',
      ctiCallError: 'Error while calling {{number}}.',
      ctiCallErrorDetails: 'please check that your phone is connected and configured correctly',
      webRtcCallError: 'Error while calling {{number}}.',
      webRtcOutgoingCallErrorDetails: 'Can\'t make the call due to a technical issue',
      webRtcIncomingCallErrorDetails: 'Can\'t answer the call due to a technical issue',
      callAnsweredElsewhere: 'The call {{number}} has been answered elsewhere.',
      callAnsweredElsewhereDetails: 'Another phone has answered this call.',
      answerCallError: 'Call from {{number}} doesn\'t exist anymore.',
      answerCallErrorDetails: 'The call was probably hung up or answered from another phone.',
      onHold: 'You are currently on hold',
      chat: 'Chat',
      ongoingCalls: 'You have ongoing calls',
      unablePlayRingSound: 'Unable to play ring sound for incoming call from: {{ number }}',
      upgradeToVideo: '{{ displayName }} wants to make a video call',
      upgradeToVideoDetails: 'Do you want to enable your camera?',
      recorder: {
        recording: 'Recording...',
        paused: 'Paused',
        record: 'Record',
        loading: 'Loading...',
      },
      meetingWith: 'Meeting with {{name}}',
      mediaSettings: 'Media Settings',
      audioOnly: 'Audio-only',
      screenshareSelectionTitle: 'Choose what to share',
      screen: 'Entire Screen',
      window: 'Window',
      joinConference: 'Join',
      roomConnectionErrorTitle: 'Error connecting to the room',
      roomConnectionErrorMessage: 'Message: ({{error}})',
      sfuErrorTitle: 'SFU configuration error',
      sfuErrorMessage: 'Please contact your administrator',
      router: {
        joining: 'Joining "{{name}}"',
        callingContact: 'Calling {{name}} ({{number}})',
        callingNumber: 'Calling number "{{number}}"',
        uncallableNumber: 'Uncallable number',
        routingCall: 'Call routing in progress',
        settingUpPhone: 'Setting up phone',
        notMeetingOwner: 'You are not one of this meeting\'s managers. Please use Wazo Meet.',
      },
      transferType: 'Transfer type',
      focusOnCallWindow: 'To call window',
      focusOnMainApp: 'To main application',
      anonymous: 'Anonymous',
      default: 'Preset',
      callerId: 'Caller ID',
    },
  },
};
