/* eslint-disable quotes */

module.exports = {
  fr: {
    main: {
      date: {
        today: "Aujourd'hui",
        yesterday: 'Hier',
      },
      error: {
        nullValue: 'Les valeurs nulles ne sont pas acceptées',
        create: 'Une erreur est survenue lors de la création de l\'item',
        save: 'Une erreur est survenue lors de la sauvegarde',
        delete: 'Une erreur est survenue en tentant de supprimer l\'item',
        load: 'Une erreur est survenue lors du chargement',
      },
      confirm: 'Confirmer',
    },
  },
  en: {
    main: {
      date: {
        today: 'Today',
        yesterday: 'Yesterday',
      },
      error: {
        nullValue: 'No empty values',
        save: 'There was an error while attempting to save',
        create: 'There was an error while attempting to create the item',
        delete: 'There was an error while attempting to delete the item',
        load: 'There was a loading error',
      },
      confirm: 'Confirm',
    },
  },
};
