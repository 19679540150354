module.exports = {
  fr: {
    settings: {
      errorConnectingVideo: 'Problème de connexion à la webcam',
      videoSelectionDisabledAudioOnly: 'La sélection est désactivée dans le mode «audio seulement»',
      audioInput: 'Entrée',
      noAudioDevices: 'Aucun périphérique audio',
      noAudioInputDevices: 'Aucun périphérique d\'entrée audio',
      audioOutput: 'Sortie voix',
      noAudioOutputDevices: 'Aucun périphérique de sortie audio',
      audioRing: 'Notifications',
      videoInput: 'Vidéo',
      noVideoInputDevices: 'Aucun périphérique d\'entrée vidéo',
      microphone: 'Microphone',
      speaker: 'Haut-parleur',
      camera: 'Caméra',
      less: 'Moins',
      plus: 'Plus',
      soundOutput: 'Sortie',
      audioOutputCouplingSelection: 'Utiliser des sorties différentes pour la voix et les notifications',
    },
  },
  en: {
    settings: {
      errorConnectingVideo: 'Error connecting to the webcam',
      videoSelectionDisabledAudioOnly: 'Selection is disabled in audio-only mode',
      audioInput: 'Input',
      noAudioDevices: 'No audio devices',
      noAudioInputDevices: 'No audio input devices',
      audioOutput: 'Voice Output',
      noAudioOutputDevices: 'No audio output devices',
      audioRing: 'Notifications',
      videoInput: 'Video',
      noVideoInputDevices: 'No video input devices',
      microphone: 'Microphone',
      speaker: 'Speaker',
      camera: 'Camera',
      less: 'Less',
      more: 'More',
      soundOutput: 'Output',
      audioOutputCouplingSelection: 'Use different outputs for voice and notifications',
    },
  },
};
