module.exports = {
  fr: {
    calls: {
      stableConnection: 'Votre connexion est stable',
      unstableConnection: 'Votre connexion semble instable',
      badConnection: 'Votre connexion est très instable, Wazo ne peut fonctionner correctement.',
      disconnected: 'Vous ne semblez pas être connecté à Internet',
      percentLost: 'Paquets perdus',
      framesPerSecond: 'Fréquence',
      roundTripTime: 'Ping',
      bandwidth: 'Bande passante utilisée',
      networkStatus: 'État du réseau',
      units: {
        percentLost: '%',
        framesPerSecond: 'ips',
        bandwidth: 'kbps',
        roundTripTime: 'ms',
      },
      ok: 'OK',
      cancel: 'Annuler',
      you: 'Vous',
      nullRoomError: 'La salle n\'existe plus',
    },
  },
  en: {
    calls: {
      stableConnection: 'Your connection is stable',
      unstableConnection: 'Your connection seems unstable',
      badConnection: 'Your connection is very unstable, Wazo can\'t work correctly.',
      disconnected: 'You don\'t seem connected to Internet',
      percentLost: 'Packet Loss',
      framesPerSecond: 'Frame Rate',
      roundTripTime: 'Ping',
      bandwidth: 'Used Bandwidth',
      networkStatus: 'Network Status',
      units: {
        percentLost: '%',
        framesPerSecond: 'fps',
        bandwidth: 'kbps',
        roundTripTime: 'ms',
      },
      ok: 'OK',
      cancel: 'Cancel',
      you: 'You',
      nullRoomError: 'The room no longer exists',
    },
  },
};
