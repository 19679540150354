module.exports = {
  fr: {
    voicemails: {
      delete: 'Supprimer',
      dialer: 'Appeler',
      deleteVoicemail: 'Supprimer',
      download: 'Télécharger',
      newVoicemail: 'Nouveau message vocal',
      emptyStateVoicemails: 'Vous n\'avez aucun message vocal.',
      loadingHistory: 'Chargement de l\'historique',
      loadingIssue: 'Problème de chargement des messages vocaux',
      fileIssue: 'Le message vocal ne peut être lu',
      fileIssueDescription: 'Cela pourrait être causé par une boite de messages pleine; essayez d\'en effacer quelques uns',
    },
  },
  en: {
    voicemails: {
      delete: 'Delete',
      dialer: 'Dialer',
      deleteVoicemail: 'Delete',
      download: 'Download',
      newVoicemail: 'New voicemail',
      emptyStateVoicemails: 'You do not have any voicemail.',
      loadingHistory: 'Loading history',
      loadingIssue: 'Voicemail loading issue',
      fileIssue: 'Cannot read the voicemail',
      fileIssueDescription: 'This could be due to a full mailbox; try deleting a few messages.',
    },
  },
};
