module.exports = {
  fr: {
    contacts: {
      favorites: 'Favoris',
      personals: 'Personnels',
      rooms: 'Conférences',
      internal: 'Internes',
      office365: 'Office 365',
      google: 'Google',
      recents: 'récents',
      phoneBook: 'Annuaire',
      online: 'en ligne',
      noCommunicationWith: 'Vous n\'avez pas encore communiqué avec',
      noCallWith: 'Vous n\'avez aucun appel avec',
      noChatWith: 'Vous n\'avez aucun message texte avec',
      noVoicemailWith: 'Vous n\'avez aucun message vocal avec',
      updateInformation: 'Modifier',
      details: 'Détails',
      addContact: 'Ajouter un contact',
      importContacts: 'Importer plusieurs contacts',
      importCsv: 'Sélectionner un fichier',
      importContactExplanation: 'Vous pouvez importer plusieurs contact à l\'aide d\'un fichier CSV.',
      importContactDownloadSample: 'Télécharger un exemple',
      contactWillBeImported: 'contact(s) à importer',
      import: 'Importer',
      importSuccess: 'Les contacts ont été importé avec succès',
      importedSuccessfully: '{{contacts}} contact(s) a été executé avec succès.',
      importError: 'Une erreur est survenue lors de l\'import de vos contacts',
      importErrorMessage: 'L\'erreur suivante est apparue: {{ message }}',
      noFavorite: 'Aucun contact favori',
      noPersonal: 'Aucun contact personnel',
      noRoom: 'Aucun contact de conférence',
      noRecent: 'Aucune activité récente',
      typeMessage: 'Écrivez votre message...',
      firstname: 'Prénom',
      lastname: 'Nom',
      number: 'Numéro',
      email: 'Courriel',
      address: 'Adresse',
      enterprise: 'Entreprise',
      birthday: 'Anniversaire',
      note: 'Note',
      save: 'Enregistrer',
      cancel: 'Annuler',
      create: 'Créer',
      required: 'Obligatoire',
      invalidEmail: 'Courriel invalide',
      invalidPhoneNumber: 'Numéro de téléphone invalide',
      confirmDelete: 'Voulez-vous vraiment supprimer ce contact ?',
      confirmDeleteTitle: 'Confirmation de la suppression',
      deleteContact: 'Supprimer le contact',
      yes: 'Oui',
      no: 'Non',
      all: 'Tous',
      chatMessages: 'Messages texte',
      voicemails: 'Messages vocaux',
      calls: 'Appels',
      other: '+{{count}} autre',
      other_plural: '+{{count}} autre',
      primary: 'Primaire',
      mobile: 'Mobile',
      extension: 'Extension',
      secondary: 'Other',
      chatNonWazoContact: 'Vous ne pouvez pas envoyer de message texte à un numéro externe pour le moment.',
      chatOurself: 'Vous ne pouvez pas vous envoyer de message pour le moment.',
      noContactInformationToDisplay: 'Aucune information concernant le contact à afficher',
      noContacts: 'Vous n\'avez aucun contact {{store}}',
      noInternal: 'Vous n\'avez aucun contact interne',
      loadingContact: 'Chargement du contact',
      loadingContacts: 'Chargement des contacts',
      loadingChats: 'Chargement des messages texte',
      delete: 'Effacer',
      sendFax: 'Envoyer un FAX',
      videoCall: 'Appel vidéo',
      call: 'Appeler',
      startChatting: 'Démarrer une conversation',
      meeting: 'Réunion',
      conferencesBlurb: 'Rejoignez des chambres de conférences pré-existantes jusqu\'à {{maxParticipants}} participants',
    },
  },
  en: {
    contacts: {
      favorites: 'Favorites',
      personals: 'Personals',
      rooms: 'Conferences',
      internal: 'Internal',
      office365: 'Office 365',
      google: 'Google',
      recents: 'recents',
      phoneBook: 'Phone Book',
      online: 'online',
      noCommunicationWith: 'You have not yet communicated with',
      noCallWith: 'You have no call with',
      noChatWith: 'You have no text message with',
      noVoicemailWith: 'You have no voicemail with',
      details: 'Details',
      updateInformation: 'Edit',
      addContact: 'Add a contact',
      importContacts: 'Import multiple contacts',
      importCsv: 'Select a csv file',
      importContactExplanation: 'You can import multiple contacts with a CSV file.',
      importContactDownloadSample: 'Download a sample file',
      contactWillBeImported: 'contact(s) will be imported',
      import: 'Import',
      importSuccess: 'Contacts were imported successfully',
      importedSuccessfully: '"{{contacts}}" contact(s) has been imported.',
      importError: 'An error occurred during the contact import.',
      importErrorMessage: 'The following error occurred: {{ message }}',
      noFavorite: 'No favorite contacts',
      noPersonal: 'No personal contacts',
      noRoom: 'No conference contacts',
      noRecent: 'No recent activity',
      typeMessage: 'Enter a message...',
      firstname: 'First name',
      lastname: 'Last name',
      number: 'Number',
      email: 'Email',
      address: 'Address',
      enterprise: 'Enterprise',
      birthday: 'Birthday',
      note: 'Note',
      save: 'Save',
      create: 'Create',
      cancel: 'Cancel',
      required: 'Required',
      invalidEmail: 'Invalid email',
      invalidPhoneNumber: 'Invalid phone number',
      confirmDelete: 'Do you want to remove this contact from your personals ?',
      confirmDeleteTitle: 'Confirm deletion',
      deleteContact: 'Delete contact',
      yes: 'Yes',
      no: 'No',
      all: 'All',
      chatMessages: 'Chat messages',
      voicemails: 'Voicemails',
      calls: 'Calls',
      other: '+{{count}} other',
      other_plural: '+{{count}} others',
      primary: 'Primary',
      mobile: 'Mobile',
      extension: 'Extension',
      secondary: 'Other',
      chatNonWazoContact: 'You cannot send text message to external number at the moment.',
      chatOurself: 'You cannot send text message to yourself at the moment.',
      noContactInformationToDisplay: 'No contact information to display',
      noContacts: 'No {{store}} contacts',
      noInternal: 'No internal contacts',
      loadingContact: 'Loading contact',
      loadingContacts: 'Loading contacts',
      loadingChats: 'Loading chat messages',
      delete: 'Delete',
      sendFax: 'Send FAX',
      videoCall: 'Video call',
      call: 'Call',
      startChatting: 'Start chatting',
      meeting: 'Meeting',
      conferencesBlurb: 'Join pre-existing conference rooms up to {{maxParticipants}} attendees',
    },
  },
};
