module.exports = {
  fr: {
    search: {
      date: {
        today: 'Aujourd\'hui',
        yesterday: 'Hier',
      },
      recentSearch: 'Anciennes recherches',
      noRecentSearch: 'Aucun élément',
      recentContactSearch: 'Contacts recherchés',
      noRecentContactSearch: 'Aucun contact recherché',
      contacts: 'Contacts',
      messages: 'Messages',
      noContactResult: 'Il n\'y a aucun contact correspondant à votre recherche',
      noMessageResult: 'Il n\'y a aucun message correspondant à votre recherche',
      createNewContact: 'Nouveau contact',
      addNewContact: 'Ajouter comme nouveau contact',
      others: 'autre(s)',
      pressEnterToCall: 'Appuyez sur Enter pour appeler',
      sendFax: 'Envoyer un FAX',
      internalUserFax: 'Contact interne, FAX non supporté',
      dayAtTime: '{{day}} à {{time}}',
    },
  },
  en: {
    search: {
      date: {
        today: 'Today',
        yesterday: 'Yesterday',
      },
      recentSearch: 'Recent searches',
      noRecentSearch: 'No recent item',
      recentContactSearch: 'Recent contacts',
      noRecentContactSearch: 'No contact searched',
      contacts: 'Contacts',
      messages: 'Messages',
      noContactResult: 'There is no contact corresponding to your query',
      noMessageResult: 'There is no message corresponding to your query',
      createNewContact: 'New contact',
      addNewContact: 'Add as a new contact',
      others: 'other(s)',
      pressEnterToCall: 'Press Enter to call',
      sendFax: 'Send FAX',
      internalUserFax: 'Internal contact, FAX not supported',
      dayAtTime: '{{day}} at {{time}}',
    },
  },
};
