module.exports = {
  fr: {
    activities: {
      copy: 'Copier',
    },
  },
  en: {
    activities: {
      copy: 'Copy',
    },
  },
};
